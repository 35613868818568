import React from 'react';
import '../../styles/App.css';
import ImageVideo from './ImageVideo';

// Primera pantalla
function ContentQuestion(props) {

  
  const imageSrc = props.getMediaContent('image', props.question.image);
  const videoSrc = props.getMediaContent('video', props.question.video);

  if(props.hidden){
    return '';
  }else{
    return (
      <div className="box" style={props.isDuplicate && !props.left?{ width: '50%', marginLeft: '30px' }: props.isDuplicate?{ width: '50%' }:{}}>
      <div className="main-question">
        <div className="question-title">
          <span dangerouslySetInnerHTML={{ __html: props.question.text[props.language] }}></span>
        </div>
        {props.question.description && props.question.description[props.language] && (
          <div className="question-description" dangerouslySetInnerHTML={{ __html: props.question.description[props.language] }}></div>
        )}
        {props.question.options.map((option, index) => {
          // Verificamos si hay un sendButton configurado en home
          // Necesitamos verificar si hay datos de sendButton en home, no solo si textButton está definido
          const hasSendButton = props.data?.home?.sendButton && 
                               props.data.home.sendButton[props.language] && 
                               props.data.home.sendButton[props.language] !== '';
          
          return (
            <button
              id={`option_${props.question.id}_${index}`}
              key={`option_${props.question.id}_${index}`}
              ref={(ref) => (props.divRef.current[index] = ref)}
              className={`question-button ${hasSendButton && props.selected === option ? 'intermitente' : ''}`}
              onClick={() => {
                if (hasSendButton) {
                  // Comportamiento normal cuando hay sendButton: seleccionar la opción
                  props.handleOptionClick(option);
                } else {
                  // Auto-submit: cuando no hay sendButton, saltamos directamente al próximo elemento
                  // En vez de usar handleOptionClick y handleSendClick (que dependen del estado selected)
                  // Pasamos la opción directamente a nextQuestionOrResult en App.js
                  
                  // Ejecutamos directamente la función nextQuestionOrResult en App.js con la opción seleccionada
                  props.directSubmit(option);
                }
              }}
            >
              <span className="letter">{props.letters[index]}</span>{option.text[props.language]}
            </button>
          );
        })}
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '20px', width: '100%' }}>
          {/* Botón de flecha atrás */}
          <button 
            className="button_start" 
            onClick={props.goToPreviousQuestion}
            style={{
              padding: '10px 15px',
              fontSize: '18px',
              fontWeight: 'bold',
              lineHeight: '1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="32" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
          </svg>
          </button>
          
          {/* Botón de enviar respuesta */}
          <button 
            className="button_start" 
            onClick={props.handleSendClick} 
            hidden={props.hiddenButton}
          >
            {props.textButton}
          </button>
        </div>
        <ImageVideo
           imageSrc = {imageSrc}
           alt="Current option"
           className="embedded-media"
           onClick={props.handleImageClick}
           videoSrc = {videoSrc}
           renderVideoEmbed = {props.renderVideoEmbed}
           hidden = {!props.isDuplicate}
        />
      </div>

</div>
  );
  }
  
}

export default ContentQuestion;

