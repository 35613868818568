import React, { useEffect, useRef, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import "./../../styles/CustomSurvey.css"; // Importamos nuestro CSS personalizado
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Popup = ({ form, onClose, onComplete, textButton, visibleQuestions, savedAnswers }) => {
  const [isCompleteButtonEnabled, setIsCompleteButtonEnabled] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const isCompleteCalled = useRef(false); // Si se ha llamado a onComplete o no, porque sino se ejecuta mas de una vez

  // Convertir visibleQuestions a un array de nombres de preguntas
  const visibleQuestionNames = visibleQuestions ? visibleQuestions.split('|') : [];

  // Filtrar las preguntas visibles
  const filteredForm = {
    ...form,
    pages: form.pages.map(page => ({
      ...page,
      elements: page.elements.filter(question => visibleQuestionNames.includes(question.name))
    }))
  };

  // Instancia única del modelo de encuesta
  const survey = useRef(new Model(visibleQuestions ? filteredForm : form)).current;

  // Pre-fill the survey with saved answers
  useEffect(() => {
    if (savedAnswers) {
      survey.data = savedAnswers;
    } else {
      survey.data = {}; // Evitar que tenga valores null
    }
    // Verificar si estamos en la última página al iniciar
    setIsLastPage(survey.currentPageNo === survey.visiblePages.length - 1);
  }, [savedAnswers, survey]);
  
  // Personalización de textos
  survey.completeText = "Enviar";
  survey.nextText = "Siguiente";

  // Quitar el estilo rojo y agregar estilo personalizado
  survey.onAfterRenderQuestion.add((sender, options) => {
    if (options.question.isRequired) {
      options.htmlElement.classList.remove("sv-question--error"); // Quita el estilo rojo
      options.htmlElement.classList.add("custom-required"); // Agrega estilo personalizado
    }
  });

  // Personalizar los mensajes de error
  survey.onValidateQuestion.add((sender, options) => {
    if (options.question.isRequired && !options.value) {
      options.error = "Este campo es obligatorio."; // Mensaje personalizado
    }
  });

  // Validación para habilitar el botón "Enviar"/"Siguiente"
  useEffect(() => {
    const checkRequiredFields = () => {
      setIsCompleteButtonEnabled(!survey.isCurrentPageHasErrors);
    };

    const updatePageStatus = () => {
      setIsLastPage(survey.currentPageNo === survey.visiblePages.length - 1);
      checkRequiredFields();
    };

    survey.onValueChanged.add(checkRequiredFields);
    survey.onCurrentPageChanged.add(updatePageStatus);
    updatePageStatus();

    return () => {
      survey.onValueChanged.remove(checkRequiredFields);
      survey.onCurrentPageChanged.remove(updatePageStatus);
    };
  }, [survey]);

  // Manejar el avance a la siguiente página
  const handleNextPage = () => {
    if (isLastPage) {
      survey.completeLastPage();
    } else {
      survey.nextPage();
    }
  };

  // Callback cuando se completa la encuesta
  useEffect(() => {
    const completeHandler = (sender) => {
      if (!isCompleteCalled.current) {
        console.log("Survey completed, submitting data:", sender.data);
        isCompleteCalled.current = true; // Set the flag to true
        const surveyData = sender.data;
        
        // Pequeño delay para asegurar que los datos se procesen correctamente
        setTimeout(() => {
          onComplete(surveyData);
          onClose();
        }, 100);
      }
    };
    
    survey.onComplete.add(completeHandler);
    
    return () => {
      survey.onComplete.remove(completeHandler);
    };
  }, [survey, onComplete, onClose]);

  return (
    <div className="popup">
      <div className="popup-inner">
        <button className="close-btn" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="survey-wrapper">
          <Survey model={survey} showNavigationButtons={false} />
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            onClick={handleNextPage}
            disabled={!isCompleteButtonEnabled}
            style={{
              marginRight: "10px",
              padding: "20px",
              fontSize: "15px",
              fontSeight: "600",
              backgroundColor: isCompleteButtonEnabled ? "#28a745" : "#ccc",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: isCompleteButtonEnabled ? "pointer" : "not-allowed",
            }}
          >
            {isLastPage ? (textButton ? textButton : "Enviar") : "Siguiente"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
