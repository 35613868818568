import React, { useRef, useState, useEffect } from 'react';
import './styles/App.css';
import Question from './components/question/Question';
import Home from './components/home/Home';
import Modal from './components/commons/Modal';
import ImageModal from './components/commons/ImageModal';
import { execScript, parseForm, saveToSessionStorage, loadScripts } from './utils/utils';
import { BrowserRouter as Router } from 'react-router-dom';
import Result from './components/result/Result';

function App() {
  // Evitamos males mayores
  const urlParams = new URLSearchParams(window.location.search);
  const logo = 'logo.png';
  const viewportWidth = urlParams.get('viewportWidth');
  const viewportHeight = urlParams.get('viewportHeight');
  const questionId = urlParams.get('question');


  if (viewportWidth && viewportHeight) {
    const appRoot = document.getElementById('root');
    appRoot.style.width = `${viewportWidth}px`;
    appRoot.style.height = `${viewportHeight}px`;
  }
  const [data, setData] = useState(null);
  const [config, setConfig] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [result, setResult] = useState(null);
  const [language, setLanguage] = useState(null);
  const [subLanguage, setSubLanguage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [main, setMain] = useState(true);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [selected, setSelected] = useState(null);
  const [textButton, setTextButton] = useState('');
  const [subTextButton, setSubTextButton] = useState('');
  const [userPath, setUserPath] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const [showDuplicateButton, setShowDuplicateButton] = useState(false);
  const [dualMode, setDualMode] = useState(true);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formFields, setFormFields] = useState([]);
  
  const [form, setForm] = useState(null); // Guardamos el formulario completo
  const [savedAnswers, setSavedAnswers] = useState({}); // Guardamos las respuestas del formulario


  const divRef = useRef([]);
  const questionHistory = useRef([]);
  const currentIndex = useRef(0);

  const getBasename = () => {
    const pathName = window.location.pathname;
    const firstSlashIndex = pathName.indexOf('/', 1);
    if (firstSlashIndex !== -1) {
      return pathName.substring(0, firstSlashIndex);
    }
    return '/';
  };

  const basename = getBasename();

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };
  const handleSubLanguageChange = (lang) => {
    setSubLanguage(lang);
  };

  const handleOptionClick = async (option) => {
    // Si la opción tiene un script, aseguramos que ya ha terminado
    if (option.script) {
      await waitForScriptCompletion();
      
      // Actualizamos storageForm si ha cambiado después del script
      const storageForm = JSON.parse(sessionStorage.getItem('storageForm'));
      if (storageForm) {
        setForm(storageForm);
      }
    }
    
    setSelected(option);
    if (option.form) {
      setFormFields(parseForm(option.form, language));
    } else {
      setFormFields([]);
    }
  };

  const resetFlow = () => {
    localStorage.clear();
    sessionStorage.clear();
    saveToSessionStorage(config, data.form, data.assistant);
    setCurrentQuestion(data.questions[0]);
    setSelected(null);
    setUserPath([]);
    questionHistory.current = [];
    currentIndex.current = 0;
    setResult(null);
    setIsFormModalOpen(false);
    setIsImageModalOpen(false);
    setIsDuplicate(false);
    setSubLanguage(language);
    setSubTextButton(textButton)
    setMain(true);
  };

  // Función para esperar a que el script termine de ejecutarse
  const waitForScriptCompletion = async (maxWaitTime = 2000) => {
    const checkInterval = 100; // ms entre cada comprobación
    let elapsedTime = 0;
    
    // Si no hay script ejecutándose, no esperamos
    if (sessionStorage.getItem("running") !== "true") {
      return;
    }
    
    console.log("Esperando a que termine la ejecución del script...");
    
    while (sessionStorage.getItem("running") === "true") {
      if (elapsedTime >= maxWaitTime) {
        console.warn("⏳ Timeout: Esperando que el script termine");
        break;
      }
      await new Promise(resolve => setTimeout(resolve, checkInterval));
      elapsedTime += checkInterval;
    }
    
    // Pequeña pausa adicional para que se estabilicen los valores
    await new Promise(resolve => setTimeout(resolve, 100));
    console.log("Script completado, continuando...");
  };

  // Nueva función para procesar directamente una opción sin depender del estado selected
  const processOptionDirectly = async (option) => {
    // Esperamos a que cualquier script en ejecución termine
    await waitForScriptCompletion();
    
    // Cargamos data de sessionStorage después de que los scripts hayan terminado
    const storageAssistant = JSON.parse(sessionStorage.getItem('storageAssistant')) || null;
    const storageForm = JSON.parse(sessionStorage.getItem('storageForm')) || null;
    if(storageAssistant)setData(storageAssistant);
    if(storageForm)setForm(storageForm);
    
    // Procesamos la opción directamente
    if ('nextQuestion' in option) {
      const nextQuestion = data.questions.find(q => q.id === option.nextQuestion);
      // Actualizamos el historial antes de cambiar el estado
      setUserPath([...userPath, { questionId: currentQuestion.id, selectedOption: option }]);
      questionHistory.current.push(currentQuestion);
      currentIndex.current += 1;
      
      // Cambiamos a la siguiente pregunta
      setCurrentQuestion(nextQuestion);
      setSelected(null);
      setMain(false);
    } else if ('result' in option) {
      const selectedResult = data.results.find(r => r.id === option.result);
      if (selectedResult) {
        // Actualizamos el historial
        setUserPath([...userPath, { questionId: currentQuestion.id, selectedOption: option }]);
        questionHistory.current.push(currentQuestion);
        currentIndex.current += 1;
        
        // Mostramos el resultado
        setResult(selectedResult);
        setCurrentQuestion(null);
        setSelected(null);
        setMain(false);
      } else {
        console.error("Result not found");
      }
    }
  };

  // Comportamiento original para cuando se usa el botón submit
  const nextQuestionOrResult = async () => {
    // Esperamos a que cualquier script en ejecución termine
    await waitForScriptCompletion();
    
    //Cargamos data de sessionStorage después de que los scripts hayan terminado
    const storageAssistant = JSON.parse(sessionStorage.getItem('storageAssistant')) || null;
    const storageForm = JSON.parse(sessionStorage.getItem('storageForm')) || null;
    if(storageAssistant)setData(storageAssistant);
    if(storageForm)setForm(storageForm);
    
    if (selected) {
      if ('nextQuestion' in selected) {
        const nextQuestion = data.questions.find(q => q.id === selected.nextQuestion);
        setCurrentQuestion(nextQuestion);
        setSelected(null);
        setMain(false);
      } else if ('result' in selected) {
         const selectedResult = data.results.find(r => r.id === selected.result);
        if (selectedResult) {
          /*if (selectedResult.form) {
            console.log("si que paso por aqui 1");
            setVisibleQuestions(selectedResult.form.all);
            setIsPopupOpen(true);
            //setFormFields(parseForm(selectedResult.form, language));
            //setResult(null);
            //setIsFormModalOpen(true);
          } else {*/
            setResult(selectedResult);
            setCurrentQuestion(null);
            setSelected(null);
            setMain(false);
          //}
        } else {
          console.error("Result not found");
        }
      }
      setUserPath([...userPath, { questionId: currentQuestion.id, selectedOption: selected }]);
      questionHistory.current.push(currentQuestion);
      currentIndex.current += 1;
    }
  };
  
  // Función para retroceder a la pregunta anterior
  const goToPreviousQuestion = () => {
    // Solo permitimos retroceder si hay historial
    if (questionHistory.current.length > 0 && currentIndex.current > 0) {
      currentIndex.current -= 1;
      
      // Retrocedemos en el historial
      const previousQuestion = questionHistory.current[currentIndex.current - 1];
      
      // Si estamos en un resultado, volvemos a la última pregunta
      if (result) {
        setResult(null);
        setCurrentQuestion(questionHistory.current[questionHistory.current.length - 1]);
      } else {
        // Volvemos a la pregunta anterior
        setCurrentQuestion(previousQuestion || data.questions[0]);
      }
      
      // Eliminamos la última entrada del path del usuario
      const newUserPath = [...userPath];
      newUserPath.pop();
      setUserPath(newUserPath);
      
      // Quitamos la selección actual
      setSelected(null);
    } else if (currentQuestion && !main) {
      // Si estamos en la primera pregunta, volvemos a la página de inicio
      setMain(true);
      setCurrentQuestion(data.questions[0]);
      setUserPath([]);
      questionHistory.current = [];
      currentIndex.current = 0;
    }
  };

  const handleFormSubmit = (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
    setIsFormModalOpen(false);
    nextQuestionOrResult();
  };

  const handleImageClick = (imageSrc) => {
    if (imageSrc) {
      setSelectedImage(imageSrc);
      setIsImageModalOpen(true);
    }
  };

  const renderVideoEmbed = (url) => {
    let embedUrl;
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.split('v=')[1] || url.split('/').pop();
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
    return (
      <iframe
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded video"
        className="embedded-media"
      ></iframe>
    );
  };

  const getMediaContent = (mediaType, mediaData) => {
    if (!mediaData) return null;

    const mediaKeys = Object.keys(mediaData);
    if (mediaKeys.length === 1) {
      return mediaData[mediaKeys[0]];
    } else {
      return mediaData[language];
    }
  };

  
        //duplicate
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

  useEffect(() => {
    // Cargar el archivo config.json desde la carpeta public
    fetch('/config/config.json')
      .then(responseConfig => responseConfig.json())
      .then(config => {
        sessionStorage.clear();
	setConfig(config);
        setDualMode(config.dualMode);
        window.addEventListener('resize', handleResize);
        const flow = config.defaultDiagram || urlParams.get('flow') || 'flow.json';
        fetch(flow)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setData(data.assistant);
            setForm(data.form);

            saveToSessionStorage(config, data.form, data.assistant);


            let defaultLanguage = urlParams.get('defaultLanguage') || Object.keys(data.assistant.languages)[0] || 'ca';
            const languageExists = data.assistant.languages.hasOwnProperty(config.defaultLanguage);
            if(languageExists)defaultLanguage = config.defaultLanguage;
            setLanguage(defaultLanguage);
            setSubLanguage(defaultLanguage);
    
            const initialQuestion = questionId
              ? data.assistant.questions.find(q => q.id === questionId)
              : data.assistant.questions[0];
          
    
            if (!initialQuestion) {
              // Manejar el caso donde el questionId no es válido
              console.error(`Question with ID ${questionId} not found.`);
              setMain(true); // Volver a la página de inicio o mostrar un mensaje de error
            } else {
              setCurrentQuestion(initialQuestion);
              setMain(questionId ? false : true);
            }
            // Añadir scripts al pie de la página
            if (config.scripts && Array.isArray(config.scripts) && config.scripts.length > 0) {
                loadScripts(config.scripts)
                    .then(() => {
                        console.log("🚀 Continuando con la ejecución...");
                        // Aquí puedes continuar con la ejecución una vez que todos los scripts están listos
                        setIsLoading(false);
                    })
                    .catch(error => console.error(error));
            } else {
                setIsLoading(false); // Si no hay scripts, continuamos sin esperar
            }
          }).catch(error => {
            console.error('Error al obtener el flujo:', error);
          });
      })
      .catch(error => console.error('Error loading config:', error));



      return () => {
        window.removeEventListener('resize', handleResize);
      };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check if sendButton is configured in home element
    const hasSendButton = data?.home?.sendButton && data?.home?.sendButton[language];
    
    setTextButton(selected && hasSendButton ? data?.home?.sendButton[language] : '');
    setHiddenButton(!selected || !hasSendButton);
    
    if(subLanguage !== ''){
      setSubTextButton(selected && hasSendButton ? data?.home?.sendButton[subLanguage] : '');
    }
  }, [selected, language, subLanguage, data?.home?.sendButton]);

  useEffect(() => {
    setShowDuplicateButton(dualMode && isLargeScreen);
  }, [isLargeScreen, dualMode]);
  
  useEffect(() => {
    //Si hay resultado y no hay formulario, ya que si hay formulario se ejecutará después de contestar el formulario
    if (result && !result.form) {
      const scriptToExecute = result.script && (result.script[language] || Object.values(result.script)[0]);
      if (scriptToExecute) {
        execScript(scriptToExecute, { data:savedAnswers });
      }
    }
  }, [result, language, savedAnswers]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const goToTest = () => {
    setMain(false);
  };
  const manageDuplicate = () => {
    setIsDuplicate(!isDuplicate);
  };

  if (main) {
      return(
        <Router basename={basename}>
        <div className="welcome">
          <Home
            showDuplicateButton={showDuplicateButton}
            home={data.home}
            langs={data.languages}
            language={language}
            subLanguage={subLanguage}
            isDuplicate={isDuplicate}
            isLargeScreen={isLargeScreen}
            goToTest={goToTest}
            handleLanguageChange={handleLanguageChange}
            handleSubLanguageChange={handleSubLanguageChange}
            logo={logo}
            getMediaContent={getMediaContent}
            renderVideoEmbed={renderVideoEmbed}
            handleImageClick={handleImageClick}
            handleDupButton={manageDuplicate}
            setCurrentQuestion={setCurrentQuestion}
            setData={setData}
            setForm={setForm}
            savedAnswers={savedAnswers}
            form={form}
            setSavedAnswers={setSavedAnswers} 
          />
        </div>
      </Router>
      );   
  } else if (result && !isFormModalOpen) {
    return (
      <Router basename={basename}>
        <div className="welcome">
          <div className="header">
            <img src={`/${logo}`} data-qa="brand-logo" className="logo" alt="" />
          </div>
          <div className="principal">
            <div className={isDuplicate && isLargeScreen?"welcome_content_duplicate":"welcome_content"}>
                <div className="container" style={{ display: 'flex', justifyContent: isDuplicate && isLargeScreen ? 'space-between' : 'center', width: '80vw', marginLeft: '30px', marginRight: '30px',  gridArea: 'main-question' }}>
                    <Result
                       text={result.text[language]}
                       userPath = {userPath}
                       language = {language}
                       result = {result}
                       isDuplicate={isDuplicate}
                       hidden = {false}
                       handleImageClick = {handleImageClick}
                       getMediaContent = {getMediaContent}
                       resetFlow = {resetFlow}
                       goToPreviousQuestion = {goToPreviousQuestion}
                       renderVideoEmbed = {renderVideoEmbed}
                       right = {false}
                       savedAnswers={savedAnswers}
                       form={form}
                       setSavedAnswers={setSavedAnswers} 
                      />
                   <Result
                       hidden={!isDuplicate || !isLargeScreen}
                       text={result.text[subLanguage]}
                       userPath = {userPath}
                       language = {subLanguage}
                       handleImageClick = {handleImageClick}
                       result = {result}
                       isDuplicate={isDuplicate}
                       getMediaContent = {getMediaContent}
                       resetFlow = {resetFlow}
                       goToPreviousQuestion = {goToPreviousQuestion}
                       renderVideoEmbed = {renderVideoEmbed}
                       right = {true}
                       savedAnswers={savedAnswers}
                       form={form}
                       setSavedAnswers={setSavedAnswers} 
                      />
                </div>
            </div>
          </div>
        </div>
      </Router>
    );
  } else {

    return (
      <Router basename={basename}>
        <React.Fragment>
          <div className="header">
            <img src={`/${logo}`} data-qa="brand-logo" className="logo" alt="" />
          </div>
          <Question
            question={currentQuestion}
            handleOptionClick={handleOptionClick}
            language={language}
            subLanguage={subLanguage}
            divRef={divRef}
            hiddenButton={hiddenButton}
            textButton={textButton}
            form={form}
            nextQuestionOrResult={nextQuestionOrResult}
            processOptionDirectly={processOptionDirectly}
            selected={selected}
            resetFlow={resetFlow}
            goToPreviousQuestion={goToPreviousQuestion}
            handleFormSubmit={handleFormSubmit}
            getMediaContent={getMediaContent}
            renderVideoEmbed={renderVideoEmbed}
            handleImageClick={handleImageClick}
            isDuplicate={isDuplicate && isLargeScreen}
            subTextButton={subTextButton}
            savedAnswers={savedAnswers}
            setSavedAnswers={setSavedAnswers}
            data={data}
          />
          {/* Form Modal */}
          <Modal
            isOpen={isFormModalOpen}
            onClose={() => setIsFormModalOpen(false)}
            onSubmit={handleFormSubmit}
            formFields={formFields}
            language={language}
            formButton={textButton}
          />
          {/* Image Modal */}
          <ImageModal
            imageSrc={selectedImage}
            isOpen={isImageModalOpen}
            onClose={() => setIsImageModalOpen(false)}
          />
        </React.Fragment>
      </Router>
    );
  }
}

export default App;

