import React, { useEffect, useRef, useState } from 'react';
import Popup from '../../components/form/Popup';
import '../../styles/App.css';
import Modal from '../commons/Modal';
import { execScript, parseForm } from '../../utils/utils';
import ImageModal from '../commons/ImageModal';
import ContentQuestion from './ContentQuestion';
import ImageVideo from './ImageVideo';

function Question(props) {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const pageRef = useRef(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);//Tenemos formulario o no
  const [visibleQuestions, setVisibleQuestions] = useState(''); //Qué preguntas mostramos

  const imageSrc = props.getMediaContent('image', props.question.image);
  const handleImageClick = () => {
    if (imageSrc) {
      setSelectedImage(imageSrc);
      setIsImageModalOpen(true);
    }
  };

  //Si hay formulario a nivel de respuesta a la pregunta, se muestra
  //sino se va a la siguiente pregunta.
  const handleSendClick = async () => {
    if(props.selected.form){
      setVisibleQuestions(props.selected.form.all);
      setIsPopupOpen(true);
    }/*else if (props.question.form) {
      setVisibleQuestions(props.question.form.all);
      setIsPopupOpen(true);
    } */else {
      const scriptToExecute = props.selected.script && (props.selected.script[props.language] || Object.values(props.selected.script)[0]);
      if (scriptToExecute) {
        await execScript(scriptToExecute, { data:props.savedAnswers });
        
        // Esperamos un momento para asegurar que los cambios en sessionStorage estén disponibles
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      setVisibleQuestions('');
      await props.nextQuestionOrResult();
    }
  };
  
  // Función para procesar directamente una opción seleccionada sin pasar por el estado selected
  const directSubmit = async (option) => {
    // IMPORTANTE: Primero actualizamos el estado selected en el componente padre
    // Esto es crítico para que el formulario funcione correctamente
    await props.handleOptionClick(option);
    
    // Verificamos si la opción tiene un formulario
    if (option.form) {
      // Configuramos el formulario directamente
      setCurrentForm(option.form);
      setVisibleQuestions(option.form.all);
      setIsPopupOpen(true);
      // No llamamos a processOptionDirectly para formularios - el flujo normal 
      // continuará cuando se complete el formulario a través de handleComplete
    } else {
      // Si no hay formulario, procesamos directamente
      // Ejecutamos cualquier script asociado con la opción
      const scriptToExecute = option.script && (option.script[props.language] || Object.values(option.script)[0]);
      if (scriptToExecute) {
        await execScript(scriptToExecute, { data:props.savedAnswers });
        
        // Esperamos un momento para asegurar que los cambios en sessionStorage estén disponibles
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      
      // Usamos la nueva función de App.js que procesa la opción directamente
      await props.processOptionDirectly(option);
    }
  };

  /* Si hay formulario a nivel de pregunta, es común a todas las respuestas y 
  se ha de mostrar antes de responder */
  useEffect(() => {
    if(props.question.script){
      const scriptToExecute = props.question.script && (props.question.script[props.language] || Object.values(props.question.script)[0]);
      if (scriptToExecute) {
        execScript(scriptToExecute, { data:props.savedAnswers });
      }
      if (props.question.form) {
        setVisibleQuestions(props.question.form.all);
        setIsPopupOpen(true);
      }
    }else if (props.question.form) {
      setVisibleQuestions(props.question.form.all);
      setIsPopupOpen(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.question.form, props.question.script, props.language, props.savedAnswers]);

  const handleOptionClick = (option) => {
    // Set the form if needed
    if (option.form) {
      setCurrentForm(option.form);
    } else {
      setCurrentForm(null);
    }
    
    // Set selected in parent
    props.handleOptionClick(option);
  };

  useEffect(() => {
    if (pageRef.current) {
      const hasMedia = props.question.image || props.question.video;
      let mainClass = props.isDuplicate?'page_duplicate' : 'page';
      pageRef.current.className = mainClass + ' ' + (hasMedia ? 'page-with-media' : 'page page-no-media');
    }
  }, [props.question, props.language, props.isDuplicate]);

  const videoSrc = props.getMediaContent('video', props.question.video);

  //Botón de enviar del formulario
  // Si hay script a nivel de respuesta, se ejecuta antes de ir a la siguiente pregunta
  //Se guardan las respuestas y vamos a la siguiente pregunta
  const handleComplete = async (surveyResults) => {
    props.setSavedAnswers(surveyResults); // Guardar las respuestas
    
    // Cerramos el modal de formulario
    setIsPopupOpen(false);
    
    // Ejecutamos cualquier script asociado a la opción seleccionada
    if(props.selected){
      const scriptToExecute = props.selected.script && (props.selected.script[props.language] || Object.values(props.selected.script)[0]);
      if (scriptToExecute) {
        await execScript(scriptToExecute, { data:surveyResults });
        
        // Esperamos un momento para asegurar que los cambios en sessionStorage estén disponibles
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    }
    
    // Avanzamos a la siguiente pregunta o resultado
    await props.nextQuestionOrResult();
  };

  

  return (
    <div ref={pageRef} className={props.isDuplicate?"page_duplicate":"page"}>
      <React.Fragment>
      {isPopupOpen && (
        <Popup
          form={props.form}
          onClose={() => setIsPopupOpen(false)}
          onComplete={handleComplete}
          textButton={props.textButton}
          savedAnswers={props.savedAnswers}
          visibleQuestions={visibleQuestions}
        />
      )}
      <div className="container" style={{  display: 'flex',  gridArea:'main-question', diplay: 'flex', }}>
          <ContentQuestion
            language={props.language}
            question={props.question}
            divRef={props.divRef}
            selected={props.selected}
            hidden =  {false}
            hiddenButton={props.hiddenButton}
            textButton={props.textButton}
            handleSendClick={handleSendClick}
            handleOptionClick={handleOptionClick}
            directSubmit={directSubmit}
            goToPreviousQuestion={props.goToPreviousQuestion}
            letters={letters}
            handleImageClick = {handleImageClick}
            getMediaContent = {props.getMediaContent}
            isDuplicate={props.isDuplicate}
            data={props.data}
          />
          <ContentQuestion
           language={props.subLanguage}
            question={props.question}
            divRef={props.divRef}
            selected={props.selected}
            hidden =  {!props.isDuplicate}
            hiddenButton={props.hiddenButton}
            textButton={props.subTextButton}
            handleSendClick={handleSendClick}
            handleOptionClick={handleOptionClick}
            directSubmit={directSubmit}
            goToPreviousQuestion={props.goToPreviousQuestion}
            letters={letters}
            handleImageClick = {handleImageClick}
            getMediaContent = {props.getMediaContent}
            isDuplicate={props.isDuplicate}
            data={props.data}
          />
      </div>
      <ImageVideo
           imageSrc = {imageSrc}
           alt="Current option"
           className="embedded-media"
           onClick={props.handleImageClick}
           videoSrc = {videoSrc}
           renderVideoEmbed = {props.renderVideoEmbed}
           hidden = {props.isDuplicate}
        />
        <div className="footer_q"></div>
        <button
          className="button_reset"
          onClick={props.resetFlow}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          Reset
        </button>
      </React.Fragment>
      {/* Form Modal */}
      <Modal
        isOpen={isFormModalOpen}
        onClose={() => setIsFormModalOpen(false)}
        onSubmit={props.handleFormSubmit}
        formFields={parseForm(currentForm, props.language)}
        formButton={props.textButton}
        isDuplicate={props.isDuplicate}
        subFormFields= {props.isDuplicate? parseForm(currentForm, props.subLanguage):null}
        subTextButton={props.subTextButton}
      />
      {/* Image Modal */}
      <ImageModal
        imageSrc={selectedImage}
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
      />
    </div>
  );
}

export default Question;

