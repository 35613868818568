import React, { useEffect, useState } from 'react';
import '../../styles/App.css';
import { execScript } from '../../utils/utils';
import ImageModal from '../commons/ImageModal';
import ContentHome from './ContentHome';
import Popup from '../../components/form/Popup';

// Primera pantalla
function Home(props) {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); // For the image modal
  const [selectedImage, setSelectedImage] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);//Tenemos formulario o no
  const [visibleQuestions, setVisibleQuestions] = useState(''); //Qué preguntas mostramos

  const handleStartClick = () => {
    if (props.home.form) {
      setVisibleQuestions(props.home.form.all);
      setIsPopupOpen(true);
    } else {
      props.goToTest();
    }
  };

  //Botón de enviar del formulario
    const handleComplete = (surveyResults) => {
      props.setSavedAnswers(surveyResults); // Guardar las respuestas
      props.goToTest();
    };

  useEffect(() => {
      const scriptToExecute = props.home.script && (props.home.script[props.language] || Object.values(props.home.script)[0]);
  
      if (scriptToExecute) {
          (async () => { // Función autoejecutable asíncrona
              await execScript(scriptToExecute, {}); // Esperamos que termine
  
              // Cargamos data de sessionStorage
              const storageForm = JSON.parse(sessionStorage.getItem('storageForm')) || null;
              const storageAssistant = JSON.parse(sessionStorage.getItem('storageAssistant')) || null;
  
              if (storageForm) props.setForm(storageForm);
              if (storageAssistant) {
                  props.setData(storageAssistant);
                  props.setCurrentQuestion(storageAssistant.questions[0]);
              }
          })();
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.home, props.language]);

  return (
    <React.Fragment>
       {isPopupOpen && (
        <Popup
          form={props.form}
          onClose={() => setIsPopupOpen(false)}
          onComplete={handleComplete}
          textButton={props.textButton}
          savedAnswers={props.savedAnswers}
          visibleQuestions={visibleQuestions}
        />
      )}
      <div className="header">
        <img src={"/" + props.logo} data-qa="brand-logo" className="logo" alt=""/>
        <button
           className="button_start"
          style={{ position: 'absolute', top: '10px', right: '20px' }}
          hidden={!props.showDuplicateButton}
          onClick={props.handleDupButton}
        >
          {props.isDuplicate?"No duplicate":"Duplicate"}
        </button>
      </div>
      <div className="principal">
        <div className={props.isDuplicate && props.isLargeScreen?"welcome_content_duplicate":"welcome_content"}>
        <div className="container" style={{  display: 'flex',  justifyContent: 'space-between'}}>
          <ContentHome
            home={props.home}
            langs={props.langs}
            language={props.language}
            goToTest={props.goToTest}
            handleLanguageChange={props.handleLanguageChange}
            getMediaContent={props.getMediaContent}
            renderVideoEmbed={props.renderVideoEmbed}
            handleImageClick={props.handleImageClick}
            setSelectedImage = {setSelectedImage}
            setIsImageModalOpen = {setIsImageModalOpen}
            isDuplicate={props.isDuplicate && props.isLargeScreen}
            hidden =  {false}
            left= {true}
          />
           <ContentHome
            home={props.home}
            langs={props.langs}
            language={props.subLanguage}
            goToTest={props.goToTest}
            handleLanguageChange={props.handleSubLanguageChange}
            getMediaContent={props.getMediaContent}
            renderVideoEmbed={props.renderVideoEmbed}
            handleImageClick={props.handleImageClick}
            hidden =  {!props.isDuplicate || !props.isLargeScreen}
            setSelectedImage = {setSelectedImage}
            setIsImageModalOpen = {setIsImageModalOpen}
            isDuplicate={props.isDuplicate && props.isLargeScreen}
            left = {false}
          />
        </div>

        </div>
      </div>
      <div className="footer">
        <div className="front_footer">
          <button
            key="comencar"
            className="button_start"
            onClick={handleStartClick}
          >
            {props.home.startButton[props.language]}
          </button>
        </div>
      </div>
      {/* Image Modal */}
      <ImageModal 
        imageSrc={selectedImage} 
        isOpen={isImageModalOpen} // Independent state for the image modal
        onClose={() => setIsImageModalOpen(false)} // Close image modal separately
      />
    </React.Fragment>
  );
}

export default Home;




